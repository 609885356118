/*
  This file is a custom implementation of
  https://github.com/auth0/auth0-angular/blob/master/projects/auth0-angular/src/lib/auth.guard.ts
  Since it was not possible to overload auth0's AuthGuard
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Route, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { EnvConfigService } from '@app/shared/service/env-config.service';
import { TimeoutWidgetService } from '@aw/timeout-widget';
import { take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class Auth0Guard implements CanActivate, CanActivateChild {
  get authSettings(): any {
    return this.envConfigService.getSettings()?.auth;
  }

  constructor(
    public authService: AuthService,
    private envConfigService: EnvConfigService,
    private timeoutWidgetService: TimeoutWidgetService
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.authService.isAuthenticated$.pipe(take(1));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const isGrandCentral = state.root.queryParams.ref === 'grand-central';
    const isAuth0Enabled = this.configurateAuth0(state);

    if (isAuth0Enabled || isGrandCentral) {
      return this.redirectIfUnauthenticated(state);
    }

    return of(true);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const isGrandCentral = state.root.queryParams.ref === 'grand-central';
    const isAuth0Enabled = this.configurateAuth0(state);

    if (isAuth0Enabled || isGrandCentral) {
      return this.redirectIfUnauthenticated(state);
    }

    return of(true);
  }

  private configurateAuth0(state: RouterStateSnapshot): boolean {
    let isConfigured = false;

    if (this.authSettings?.enabled) {
      this.initializeTimeOut(state);
      isConfigured = true;
    }

    return isConfigured;
  }

  private redirectIfUnauthenticated(
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isAuthenticated$.pipe(
      tap((loggedIn) => {
        if (!loggedIn) {
          this.redirectToLogin(state);
        }
      })
    );
  }

  private redirectToLogin(state: RouterStateSnapshot): void {
    const url = (state.url || '').replace('logout=true', '');

    this.authService.loginWithRedirect({
      appState: { target: url },
      prompt: 'login'
    });
  }

  public initializeTimeOut(state: RouterStateSnapshot): void {
    let timeout = this.authSettings?.timeout;
    timeout = !timeout && timeout !== 0 ? 14 : timeout;
    this.timeoutWidgetService.initializeTimeoutService(timeout, '/auth/logout');

    if (!(state.url || '').includes('/auth')) {
      window.sessionStorage.setItem('aw-auth-redirect-to', state.url);
    }
  }
}
