/*!
 * American Well Online Care
 *
 * Copyright © 2021 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import decode from 'jwt-decode';

export class TokenContext {
    public header: any;
    public payload: any;

    constructor(header: any, payload: any) {
        this.header = header;
        this.payload = payload;
    }

    /**
     * Extracts context from the token.
     *
     * @param token the token
     *
     * @returns the extracted token context or undefined if the token is empty
     */
    public static parse(token: string): TokenContext {
        if (!token || token.trim() === '') {
            return;
        }

        const header = decode<any>(token, { header: true });
        const payload = decode<any>(token);
        return new TokenContext(header, payload);
    }

    /**
     * Was this visit initiated by AmWell Now.
     *
     * @returns True if the visit was initiated by AmWell Now.
     */
    public isAmWellNow(): boolean {
        return this.payload?.ehrId === 'amwellnow';
    }

    /**
     * Was this visit initiated by Cerner.
     *
     * @returns True if the visit was initiated by Cerner.
     */
    public isCerner(): boolean {
        return this.payload?.ehrType === 'cerner';
    }

    /**
     * Is the user a provider.
     *
     * @returns True if the token has a provider role.
     */
    public isProvider(): boolean {
        return this.payload?.role === 'PRACTITIONER';
    }
}
