import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { client, getLegalDocuments, aehrPublicConfig } from '@aw/cms-client';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { TokenContext } from '@app/shared/model/token-context.model';
import i18next, { i18n } from 'i18next';
import { BehaviorSubject, from, Observable } from 'rxjs';
import 'url-search-params-polyfill';
import { IConfiguration, IImageURLs, REQUIRED_INTAKE_PAGE_CONTROLS } from '../interface/configuration-response.interface';
import { EnvConfigService, EnvUrlNameToken } from './env-config.service';
import { TokenResolverService } from './token-resolver.service';
import { WINDOW_TOKEN } from './window-token';
import { filter, map, switchMap } from 'rxjs/operators';
import { AppConfig } from '@app/shared/model/app-config.model';
import { EnvironmentName } from '@app/shared/interface/environment-name.type';
import { PARTICIPANT_ROLE } from '@amwellnow/app-embedded/constants';
import { DOCUMENT_TOKEN } from '@app/shared/service/document-token';
import { LegalDocument } from '@app/shared/model/legal-document.model';
import { Logger } from 'loglevel';
import { LOGGER_TOKEN } from '@app/shared/service/logger-token';

const CMS_LOCALES_MAP = {
  en: 'en-US',
  es: 'es',
  he: 'he-IL'
};

@Injectable()
export class AppInitService {
  name: string;
  brand: string;
  brandName: string;
  product = 'aehr-client';
  imageURLs: IImageURLs;
  emails: Array<string>;
  subdomain: string;
  title: string;
  dir: string;
  subscriptionCheck: boolean;
  initPromise: Promise<IConfiguration>;
  iamEnabled: boolean;
  settings: AppConfig;
  conferenceVendorId: string;
  supportEmail: string;
  supportPhoneNumber: string;
  supportContactText: string;
  touAcknowledgementText: string;
  touLegalText: string;
  amwellNowSupportNumber: string;
  requiredIntakePageControls: REQUIRED_INTAKE_PAGE_CONTROLS[] = [
    REQUIRED_INTAKE_PAGE_CONTROLS.PATIENT_NAME,
    REQUIRED_INTAKE_PAGE_CONTROLS.PATIENT_PHONE_NUMBER,
    REQUIRED_INTAKE_PAGE_CONTROLS.ACCEPTED_NOPP
  ];
  ehrConfigurations: any;
  appClasses: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  hideAwPanelOnMainPageSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  supportedLocales: string[] = ['en-US', 'es'];
  hideSupportNumber: boolean;
  hideAmwellElements: boolean;
  customDomains: {
    dev: string;
    stg01: string;
    cvg01: string;
  };
  useRuntime: boolean;
  skipTechCheck: false;
  amwellNowUrl: string;
  localeNavigator: string;
  private i18next: i18n;
  private brandConfigurations;
  private cmsClientInitialized$ = new BehaviorSubject<boolean>(false);
  private settingsInitialized$ = new BehaviorSubject<boolean>(false);
  publicConfig: any;
  extendedConfig: any;

  constructor(
    private http: HttpClient,
    private tokenResolverService: TokenResolverService,
    private readonly titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private envConfigService: EnvConfigService,
    @Inject(WINDOW_TOKEN) public window: Window,
    @Inject(EnvUrlNameToken) private envUrlName: EnvironmentName,
    @Inject(DOCUMENT_TOKEN) public document: Document,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(LOGGER_TOKEN) public log: Logger,
  ) {
    this.setInitPromise();
    this.i18next = i18next;
    this.localeNavigator = navigator.language.split('-')[0];
  }

  get browserLocale(): string {
    const defaultLanguage = 'en-US';
    const supportedLang = this.supportedLocales.includes(this.localeNavigator);

    return (supportedLang ? this.localeNavigator : defaultLanguage);
  }

  get cmsClientInitialized(): Observable<boolean> {
    return this.cmsClientInitialized$.asObservable();
  }

  get settingsInitialized(): Observable<boolean> {
    return this.settingsInitialized$.asObservable();
  }

  private setFavicon(favicon: string) {
    if (!favicon) {
      return;
    }
    const faviconIdName = 'appFavicon';
    const appHeadFavicon = this.document.head.querySelector(`#${faviconIdName}`);
    if (appHeadFavicon) {
      this.document.head.removeChild(appHeadFavicon);
    }
    const appFavicon = this.document.createElement('link');
    appFavicon.id = faviconIdName;
    appFavicon.rel = 'icon';
    appFavicon.type = 'image/x-icon';
    appFavicon.href = favicon;
    this.document.head.appendChild(appFavicon);

    const appleTouchIcon = this.document.head.querySelector(`link[rel='apple-touch-icon']`) as HTMLLinkElement;
    if (appleTouchIcon) {
      appleTouchIcon.href = favicon;
    }
  }

  private setAppManifest(value: IConfiguration) {
    const appManifestIdName = 'appManifest';
    const appHeadManifest = this.document.head.querySelector(`#${appManifestIdName}`);
    if (appHeadManifest) {
      this.document.head.removeChild(appHeadManifest);
    }

    const appManifest = this.document.createElement('link');
    appManifest.rel = 'manifest';
    appManifest.id = appManifestIdName;
    const myDynamicManifest = {
      name: value?.names?.[0]?.name || 'Converge',
      short_name: value?.names?.[0]?.name || 'Converge',
      theme_color: '#ffffff',
      background_color: '#ffffff',
      display: 'standalone',
      icons: [{
        src: value?.imageURLs?.favicon || './apple-touch-icon.png',
        sizes: '48x48',
        type: 'image/png'
      }]
    };
    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], {type: 'application/json'});
    const manifestURL = URL.createObjectURL(blob);
    appManifest.setAttribute('href', manifestURL);
    this.document.head.appendChild(appManifest);
  }

  public setInitPromise() {
    this.initPromise = this.init();
  }

  public async init(): Promise<IConfiguration> {
    const params: { [param: string]: string } = { locale: navigator.language };
    let configuration: IConfiguration;
    let forcedLocal;
    let publicConfigError = false;

    params.buster = new Date().getTime().toString();

    this.brand = await this.findCurrentBrand();

    // do not criticize my re-fetching of this no-store cacheless file, i need it, and this service is crazy.
    // you can't circular reference into env-config.service who already has this, lazily, maybe, but maybe not yet.
    return this.getSettings()
      // also the goal here is to not load `configuration.json`, but rather, brandingRoot + $tenant + config.json and
      // mix that with brandingRoot + config/base-config.json
      .then((settings: AppConfig) => {
        this.initializeCmsClient();

        return this.getAehrPublicConfig()
          .then((response: any) => {
            const responseBranding = response.branding;
            const responseLegalDocs = response['legal-documents'];
            const responseSupportContact = response['support-contact'];
            configuration = {
              brand: this.brand,
              imageURLs: {
                background: responseBranding?.backgroundImageDesktop?.url || '',
                logo: responseBranding?.logo?.url || '',
                header: responseBranding?.headerImageDesktop?.url || ''
              },
              touLegalText: responseLegalDocs?.length ? responseLegalDocs[0]?.documentText : '',
              touAcknowledgementText: responseLegalDocs?.length ? this.getTouLink(responseLegalDocs[0]) : '',
              supportEmail: responseSupportContact?.email || '',
              supportPhoneNumber: responseSupportContact?.phoneNumber || '',
              supportContactText: responseSupportContact?.supportContactText || '',
              // TODO: these will be set once migration to CMS is done
              name: '',
              emails: [],
              subdomain: '',
              title: '',
              dir: '',
              subscriptionCheck: false,
              conferenceVendorId: '',
              hideAmwellElements: responseBranding?.hideAmwellElements
            };
            return configuration;
          })

          .catch((e) => {
            console.error('Error fetching aehrPublicConfig');
            this.log.error('Error fetching aehrPublicConfig', e);
            publicConfigError = true;
            return configuration;
          })

          .finally(() => {
            // ! Important:
            // For now we are going to make use of Branding Assets data in all cases, since so far the information is not complete in
            // CMS and this is causing some issues like the ones mentioned in tickets BRAND-587 or ANC-1156, since for now Contentful
            // does not contain important attributes such as:
            // >> names: Tenant names by location.
            // >> subdomains: Customized domains per environment.
            // >> favicons: Favicon of the tenants by location.
            // >> among some others.
            // As soon as the CMS migration is completed, the use of Branding Assets can be omitted.
            return this.getBrandingAssets(settings, params).toPromise()
              .catch((e) => {
                // the fetching of brandingRoot/config/configuration.json failed. use the local fallback configuration,
                // which only includes basic branding based on amwl tenant, all served locally.
                console.error('Error fetching remote branding configurations, will attempt fallback');
                this.log.error('Error fetching remote branding configurations, will attempt fallback', e);
                forcedLocal = true;
                return this.http.get<any>('assets/config/fallback-configuration.json', { params }).toPromise();
              })
              .then(async (config) => {
                this.brandConfigurations = config.brandConfigurations || [];
                const brand = await this.findCurrentBrand();
                const current = await this.findCurrentBrandConfiguration(config, brand);
                this.customDomains = current.customDomains;
                if (this.customDomains) {
                  this.amwellNowUrl = this.customDomains[this.envUrlName];
                }
                if (!this.amwellNowUrl) {
                  this.amwellNowUrl = this.getAmwellNowUrl(current.subdomain);
                }
                this.hideAmwellElements = current.hideAmwellElements;
                this.subdomain = current.subdomain;
                this.router.events.subscribe((data) => {
                  if (data instanceof RoutesRecognized) {
                    this.setPageTitle(current, data.state.root.firstChild.data);
                  }
                });
                this.hideSupportNumber = current?.hideSupportNumber;
                this.requiredIntakePageControls = current?.requiredIntakePageControls || this.requiredIntakePageControls;
                // normalize the images so they are useful
                current.imageUrls.forEach((images) => {
                  const normalizedImages = images.images;
                  images.images.forEach((imageConfig) => {
                    // if image is absolute, pass through, otherwise prefix with brandingRoot:
                    // unless `brandingRoot` had some issue loading, in which case we're using `fallback`, and that means
                    // we need to override using brandingRoot all together
                    const actualImage = (forcedLocal || /^https?:/i.test(imageConfig.image)) ? imageConfig.image : `${this.settings.brandingRoot}/${imageConfig.image}`;
                    normalizedImages[imageConfig.type] = actualImage;
                  });
                  current.imageURLs = normalizedImages;
                });
                const supportContactText = this.getSupportTexts(current);
                Object.assign(current, supportContactText);
                // get touTexts depending on browser language
                const touLegalText = this.getTouTexts(current, config.defaultTouLocaleTexts);
                Object.assign(current, touLegalText);
                Object.assign(this, current);
                if (current.dir) {
                  document.dir = current.dir;
                }

                if (current?.imageURLs?.favicon) {
                  this.setFavicon(current?.imageURLs?.favicon);
                }
                this.setAppManifest(current);

                return current;
              })
              .finally(() => {
                // we need to update [this] with [configuration] after merge of [current]
                this.imageURLs.background = configuration?.imageURLs?.background || this.imageURLs.background;
                this.imageURLs.logo = configuration?.imageURLs?.logo || this.imageURLs.logo;
                this.imageURLs.header = configuration?.imageURLs?.header || this.imageURLs.header;
                this.touLegalText = configuration?.touLegalText || this.touLegalText;
                this.touAcknowledgementText = configuration?.touAcknowledgementText || this.touAcknowledgementText;
                this.supportContactText = configuration?.supportContactText || this.supportContactText;
                this.supportEmail = configuration?.supportEmail || this.supportEmail;
                this.supportPhoneNumber = configuration?.supportPhoneNumber || this.supportPhoneNumber;
                this.hideAmwellElements = configuration?.hideAmwellElements || this.hideAmwellElements;
                this.requiredIntakePageControls = configuration?.requiredIntakePageControls || this.requiredIntakePageControls;
                this.settingsInitialized$.next(true);
              });
          });
      });
  }

  getSettings(): Promise<AppConfig> {
    if (this.settings) {
      return Promise.resolve(this.settings);
    }
    return this.envConfigService.init()
      .then((settings: AppConfig) => {
        this.settings = settings;
        return settings;
      });
  }

  async getAehrPublicConfig(): Promise<any> {
    if (this.publicConfig) {
      return Promise.resolve(this.publicConfig);
    }
    const token = await this.findToken();
    const tokenContext: TokenContext = TokenContext.parse(token);

    return aehrPublicConfig({
      tenant: this.brand,
      product: tokenContext?.isAmWellNow() ? 'AmwellNow' : this.product,
      documentType: 'Terms of Use',
      locale: CMS_LOCALES_MAP[this.browserLocale] || this.browserLocale,
      preview: false
    })
      .then((response: any) => {
        this.publicConfig = response;
        return response;
      });
  }

  hideAwPanelOnMainPage() {
    this.hideAwPanelOnMainPageSubject$.next(true);
  }

  showAwPanelOnMainPage() {
    this.hideAwPanelOnMainPageSubject$.next(false);
  }

  findSubdomainByBrand(brand) {
    const upperCaseBrand = brand.toUpperCase();
    return this.brandConfigurations.find(item => item.brand.toUpperCase() === upperCaseBrand);
  }

  getLegalDocuments(product = this.product, role = PARTICIPANT_ROLE.PATIENT): Observable<any> {
    const TOU_DOCUMENT_TYPE = 'Terms of Use';
    return this.cmsClientInitialized.pipe(
      filter((isInitialized) => isInitialized),
      switchMap(
        () => {
          const config = {
            tenant: this.brand?.toUpperCase(),
            product,
            documentType: TOU_DOCUMENT_TYPE,
            locale: CMS_LOCALES_MAP[this.browserLocale] || this.browserLocale,
            role
          };

          return from(getLegalDocuments(config)).pipe(
            map(items => {
              const data = items?.[0];
              if (data) {
                return {
                  ...data,
                  acknowledgementText: this.getTouLink(data)
                } as LegalDocument;
              }
            })
          );
        }
      )
    );
  }

  /**
   * @method addAppClasses
   * @description Add application class names
   */
  public addAppClasses(classes: string | string[]) {
    const { value } = this.appClasses;
    const values: string[] = Array.isArray(classes) ? classes : [classes];
    return this.appClasses.next([...values, ...value]);
  }

  /**
   * @method getAppClasses
   * @description Get class names
   */
  public getAppClasses() {
    return this.appClasses;
  }

  /**
   * @method clearAppClasses
   * @description Clear application class names
   */
  public clearAppClasses() {
    return this.appClasses.next([]);
  }

  getAmwellNowUrl(subdomain?: string): string {
    if (subdomain) {
      const separator = '://';
      const [protocol, url] = this.settings.amwellNowUrl.split(separator);
      return `${protocol}${separator}${subdomain}.${url}`;
    } else {
      return this.settings.amwellNowUrl;
    }
  }

  private getTouLink(data: LegalDocument): string {
    const acknowledgementText = document.createElement('span');
    acknowledgementText.innerHTML = data.acknowledgementText.trim();
    const touLink = acknowledgementText.querySelector('a');

    if (touLink) {
      touLink.setAttribute('target', '_blank');
      touLink.setAttribute('title', this.i18next.t('open_in_new_tab'));
      const openInNewTabImg = this.document.createElement('span');
      const text = this.document.createTextNode('open_in_new');
      openInNewTabImg.appendChild(text);
      openInNewTabImg.classList.add('material-icons');
      openInNewTabImg.classList.add('new-tab-icon');
      openInNewTabImg.setAttribute('aria-hidden', 'true');
      touLink.appendChild(openInNewTabImg);
    }

    return acknowledgementText.outerHTML;
  }

  private getBrandFromQueryParams() {
    const urlParams = new URLSearchParams(this.window.location.search);

    return urlParams.get('fromBrand') || urlParams.get('tenantId');
  }

  private initializeCmsClient() {
    if (!client.__initialized) {
      const clientInitProps = {
        product: this.product,
        url: this.settings.cmsProxyUrl,
        environment: this.settings.cmsEnvironment || 'master',
        // bento configs don't actually do this in CMS
        // they could, but all the aehr-client ones will be single configs with
        // role differentiating business logic here
        role: PARTICIPANT_ROLE.PRACTITIONER,
        tenant: this.brand?.toUpperCase()
      };
      // this.log.info('CMS initializing with', clientInitProps);
      this.log.info('CMS initializing with');
      client.init(clientInitProps);
      this.cmsClientInitialized$.next(true);
    }
  }

  private async findCurrentBrand(): Promise<any> {
    // try the token query param
    const token = await this.findToken();
    const queryBrand = this.getBrandFromQueryParams();
    let brand;
    if (token) {
      const tokenPayload = TokenContext.parse(token).payload;
      if (tokenPayload.tenantKey) {
        const tenantKey = tokenPayload.tenantKey.toUpperCase();
        localStorage.setItem('tenantKey', tenantKey);
        brand = tenantKey;
      }
    } else if (queryBrand) {
      const upperCaseBrand = queryBrand.toUpperCase();
      brand = upperCaseBrand;
    } else {
      const lastKnownTenant = localStorage.getItem('tenantKey');
      if (lastKnownTenant) {
        brand = lastKnownTenant.toUpperCase();
      }
    }
    return brand;
  }

  async findCurrentBrandConfiguration(config, queryBrand): Promise<any> {
    let currentBrandConfiguration;
    const defaultDomain = 'AMWL';
    // try the token query param
    const token = await this.findToken();
    if (token) {
      const tokenPayload = TokenContext.parse(token).payload;
      if (tokenPayload.tenantKey) {
        const tenantKey = tokenPayload.tenantKey.toUpperCase();
        localStorage.setItem('tenantKey', tenantKey);
        currentBrandConfiguration = config.brandConfigurations.find(brand => brand.brand.toUpperCase() === tenantKey);
      }
    } else if (queryBrand) {
      const upperCaseBrand = queryBrand.toUpperCase();
      const brandData = config.brandConfigurations.find(item => item.brand.toUpperCase() === upperCaseBrand);
      currentBrandConfiguration = brandData;
    } else {
      const lastKnownTenant = localStorage.getItem('tenantKey');
      if (lastKnownTenant) {
        currentBrandConfiguration = config.brandConfigurations.find(brand => brand.brand.toUpperCase() === lastKnownTenant.toUpperCase());
      }
    }

    if (!currentBrandConfiguration) {
      // fallback to AMWL if we can't find the brand based on the subdomain or token
      currentBrandConfiguration = config.brandConfigurations.find(brand => brand.brand.toUpperCase() === defaultDomain);
    }

    this.showAwPanelOnMainPage();

    return currentBrandConfiguration;
  }

  private getTouTexts(config, defaultTouLocaleTexts): { [prop: string]: string } {
    const touLocaleTexts = config.touLocaleTexts ?? defaultTouLocaleTexts;
    const touTexts = touLocaleTexts.find(tlt => {
      return tlt.locale.replace('_', '-').includes(this.browserLocale);
    });

    return touTexts ? touTexts.value : {};
  }

  private getSupportTexts(config): { [prop: string]: string } {
    const supportTexts = config.supportContactLocaleText.find(tlt => {
      return tlt.locale.replace('_', '-').includes(this.browserLocale);
    });

    return supportTexts ? supportTexts.value : {};
  }

  private async findToken(): Promise<any> {
    // check the query string for a "token" parameter
    const urlParams = new URLSearchParams(this.window.location.search);
    let token = urlParams.get('token');

    // if not found, look for session storage
    if (!token) {
      token = sessionStorage.getItem('aw-bearer-token');
    }

    // if not found in params or session then try to resolve token
    if (!token || token === 'null') {
      const data = await this.tokenResolverService.getToken(urlParams).toPromise();
      token = data?.token;
    }

    return token;
  }

  private setPageTitle(current, routeData) {
    const defaultBrantName = 'Amwell';
    const languageObj = current?.names.find(tlt => {
      return tlt?.locale?.replace('_', '-').includes(this.browserLocale);
    });
    this.brandName = languageObj?.name ?? defaultBrantName;
    const pageTitleKey = routeData?.pageTitleKey;
    const titleTranslate = this.i18next.t(pageTitleKey ?? '');
    const defaultTranslate = this.i18next.t('telehealth_visit');
    const pageTitle = `${titleTranslate} ${defaultTranslate} ${this.brandName}`;
    this.titleService.setTitle(pageTitle);
  }

  private getBrandingAssets(settings: AppConfig, params: { [param: string]: string }): Observable<any> {
    return this.http.get<any>(`${settings.brandingRoot}/config/configuration.json`, { params });
  }
}
