import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnvConfigService } from '@app/shared/service/env-config.service';

@Component({
  selector: 'app-auth',
  template: '',
})
export class AuthComponent implements OnInit {

  constructor(
    private envConfigService: EnvConfigService,
    private router: Router
  ) { }

  ngOnInit(): void {

    if (this.envConfigService.getSettings()?.auth?.enabled) {
      const returnTo = window.sessionStorage.getItem('aw-auth-redirect-to');

      if (!!returnTo) {
        this.goTo(returnTo);
      } else {
        this.router.navigate(['/error']);
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  private goTo(path): void {
    window.location.href = `${window.location.origin}${ path }`;
  }
}
